<template>
  <b-dialog v-model="visible" width="600px" title="工作情况落实">
    <el-form ref="form" class="g-p-20" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="内容" prop="content">
        <el-input
          v-model="formData.content"
          type="textarea"
          :autosize="{ minRows: 3 }"
          placeholder="输入内容"
        ></el-input>
      </el-form-item>

      <el-form-item label="图片">
        <c-upload-image v-model="formData.images"></c-upload-image>
      </el-form-item>
      <el-form-item label="附件">
        <c-upload-media v-model="formData.media_keys" :default="defaultMedia"></c-upload-media>
      </el-form-item>

      <div style="text-align: right;">
        <b-btn-confirm :loading="submitLoading" @click="onSubmit"></b-btn-confirm>
      </div>
    </el-form>
  </b-dialog>
</template>

<script>
import CUploadImage from '@/components/upload/image'
import CUploadMedia from '@/components/upload/media'
import { createAction, updateAction } from '@/api/farming/index'

export default {
  components: {
    CUploadImage,
    CUploadMedia,
  },
  data() {
    return {
      pId: null,
      visible: false,
      submitLoading: false,
      defaultMedia: [],
      formData: {},
      formRules: {
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
      },
    }
  },
  methods: {
    open(val, pId) {
      this.pId = pId
      this.formData = val
      this.defaultMedia = val.media_keys
      this.formData.media_keys = []
      this.visible = true
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.pId) {
            createAction(this.pId, this.formData)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            updateAction(this.formData.id, this.formData)
              .then(res => {
                this.$message.success('修改成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
